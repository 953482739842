
import Splide from "@splidejs/splide";
import GLightbox from "glightbox";
import { ProportionalImageNormalisation } from "@keodesign/proportional-image-normalisation";


export function init() {
	GLightbox({
		touchNavigation: true,
		loop: true,
		autoplayVideos: true
	});

	
	setupMapClick();
	setupTestimonialSlider();
	setupGallerySlider();
	setupSimulateClick();
	ProportionalImageNormalisation(".scale-me", 100, 0.525);
	
}
export function setupMapClick(){
	const map_click_list = document.querySelectorAll("#map-click, .map-click");
	if (map_click_list) {
		map_click_list.forEach((map_click) => {
			map_click.addEventListener("click", () => {
				// shield the map on mobile for easy scrolling
				map_click.style.display = "none";
			});
		});
	}
}

export function setupSimulateClick(){
	const simulate_click = document.querySelectorAll(".simulate_click");

	simulate_click.forEach(el => {
		const target_el = el.dataset.target;

		el.addEventListener("click", () =>{
			document.querySelector(target_el).click();
		});
	});

}

export function setupTestimonialSlider(){
	var testimonialSlider = document.querySelectorAll(".testimonial-slider");
	
	if(testimonialSlider){
		for (var i = 0; i < testimonialSlider.length; i++ ) {
			var slider = testimonialSlider[i].querySelector(".slider");
			
			new Splide( slider, {
				type   : "slide",
				rewind    : true,
				pagination: true,
				arrows    : false,
				autoplay: true,
			}).mount();
		}
	}
}

export function setupGallerySlider(){
	var gallerySlider = document.querySelectorAll(".gallery-slider");
	
	if(gallerySlider){
		gallerySlider.forEach(slider => {
			new Splide( slider, {
				type   : "slide",
				rewind    : true,
				pagination: false,
				arrows    : false,
				autoplay: true,
				perPage: 3,
				breakpoints: {
					768: {
						start:2,
						perPage: 2,
						padding: "20%",
					},
					576: {
						perPage: 1,
						padding: "20%",
					},
				}
			}).mount();
		});
	}
}


window.addEventListener("DOMContentLoaded", init);